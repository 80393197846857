// type ReturnType = {
//     pageProps: any
//     Component: NextComponentType<NextPageContext>
//     loginStatus: LOGINSTATUS
//   }
import { useContext } from 'react'

import {
  AppolloClientContextType,
  PermissionContextType,
  SnackbarContextType,
  ThemeContextType,
  UserContextType,
  apolloClientContext,
  permissionsContext,
  snackbarContext,
  themeContext,
  userContext,
} from '../utils/context-utils'

type HookType = () => {
  apolloClient: AppolloClientContextType
  snackbar: SnackbarContextType
  themePalette: NonNullable<ThemeContextType>['themePalette'] | undefined
  setThemePalette: NonNullable<ThemeContextType>['setThemePalette'] | undefined
  user: UserContextType
  permissions: PermissionContextType
}

export const useGetAllAppContext: HookType = () => {
  const apolloClient = useContext(apolloClientContext)
  const snackbar = useContext(snackbarContext)
  const theme = useContext(themeContext)
  const user = useContext(userContext)
  const permissions = useContext(permissionsContext)

  return {
    apolloClient,
    snackbar,
    themePalette: theme?.themePalette,
    setThemePalette: theme?.setThemePalette,
    user,
    permissions,
  }
}
