import Image, { ImageLoader, ImageProps } from 'next/image'
import { forwardRef, useMemo } from 'react'

import { CSS, styled } from '../stitches.config'

export type ShoImageProps = ImageProps & {
  /**
   * base64 placeholder image that will be used as blury placeholder while image is loading
   */
  blurryPlaceholder?: string | null
  /**
   * Does not transform the URL if on sho-app.
   * Defaults to false
   */
  doNotTransformURL?: boolean
  /**
   * set css of parent element
   */
  fillWrapperCss?: CSS
  /**
   * Explicits if the asset is public or private (AWS).
   * Defaults to false
   */
  isPrivate?: boolean
  /**
   * Set the custom css on the image
   * @default {}
   */
  imageCss?: CSS
  /**
   * JWT token used for private assets.
   */
  jwt?: 'string'
}

export const ShoImage = forwardRef<HTMLImageElement, ShoImageProps>(
  (
    {
      imageCss,
      fillWrapperCss,
      src,
      doNotTransformURL = false,
      isPrivate = false,
      jwt,
      blurryPlaceholder,
      alt = 'image',
      className,
      ...rest
    },
    forwardedRef,
  ) => {
    const transformedSrc = useMemo(() => {
      if (
        !doNotTransformURL &&
        typeof src === 'string' &&
        process.env.NEXT_PUBLIC_PUBLIC_ASSET &&
        process.env.NEXT_PUBLIC_PRIVATE_ASSET &&
        !src.startsWith('/') &&
        !src.startsWith('http')
      ) {
        const baseUrl = isPrivate ? process.env.NEXT_PUBLIC_PRIVATE_ASSET : process.env.NEXT_PUBLIC_PUBLIC_ASSET
        return (src = `https://${baseUrl}/${src}${isPrivate && jwt ? `?jwt=${jwt}` : ''}`)
      }
      return src
    }, [src, doNotTransformURL, isPrivate, jwt])

    const loader = useMemo(() => (doNotTransformURL ? ({ src }) => src : myLoader), [doNotTransformURL])
    const placeholder = useMemo(
      () => (blurryPlaceholder ? 'blur' : 'empty') as ImageProps['placeholder'],
      [blurryPlaceholder],
    )
    const imgProps = {
      ...rest,
      loader,
      placeholder,
      blurDataURL: blurryPlaceholder || undefined,
      src: transformedSrc,
      alt,
      css: imageCss,
    } satisfies ImageProps & { css: CSS | undefined }

    if (!imgProps.src) {
      console.error('ShoImage attempted to be used with missing src')
      return null
    }

    return rest.fill ? (
      <FillImageWrapper css={{ position: 'relative', ...fillWrapperCss }} className={className} ref={forwardedRef}>
        <ImageStyled {...imgProps} />
      </FillImageWrapper>
    ) : (
      <ImageStyled {...imgProps} className={className} ref={forwardedRef} />
    )
  },
)
// ShoImage.toString = () => `.${ImageStyled.className}`
ShoImage.toString = () => `.sho-image`
ShoImage.displayName = 'ShoImage'

const myLoader: ImageLoader = ({ src, width, quality }) => {
  if (typeof src !== 'string') return ''
  if (src.includes('.ico')) return src
  const withAndQualityQueryParams = `w=${width}${quality ? `&q=${quality}` : ''}`
  if (src.includes('?')) {
    return `${src}&${withAndQualityQueryParams}`
  }
  return `${src}?${withAndQualityQueryParams}`
}

const FillImageWrapper = styled('div', {})

const ImageStyled = styled(Image, {})
