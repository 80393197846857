import * as SeparatorPrimitive from '@radix-ui/react-separator'
import type * as Stitches from '@stitches/react'
import React from 'react'

import { CSS, styled } from '../stitches.config'

const StyledSeparator = styled(SeparatorPrimitive.Root, {
  background: '$gs6',

  variants: {
    orientation: {
      horizontal: {},
      vertical: {},
    },
    size: {
      small: {},
      medium: {},
      large: {},
    },
  },

  compoundVariants: [
    {
      orientation: 'horizontal',
      size: 'small',
      css: {
        height: '1px',
        width: '100%',
      },
    },
    {
      orientation: 'horizontal',
      size: 'medium',
      css: {
        height: '3px',
        width: '100%',
      },
    },
    {
      orientation: 'horizontal',
      size: 'large',
      css: {
        height: '5px',
        width: '100%',
      },
    },
    {
      orientation: 'vertical',
      size: 'small',
      css: {
        width: '1px',
      },
    },
    {
      orientation: 'vertical',
      size: 'medium',
      css: {
        width: '3px',
      },
    },
    {
      orientation: 'vertical',
      size: 'large',
      css: {
        width: '5px',
      },
    },
  ],

  defaultVariants: {
    size: 'small',
    orientation: 'horizontal',
  },
})

type DividerProps = {
  /**
   * Set Css properties
   */
  css?: CSS

  /**
   * Size of the Divider, Defalut value is 'small'
   */
  size?: 'small' | 'medium' | 'large'

  /**
   * Vertical or Horizontal Divider Setting. Defalue value is 'horizontal'
   */
  orientation?: 'vertical' | 'horizontal'
} & Stitches.VariantProps<typeof StyledSeparator>

export const Divider: React.FC<DividerProps> = props => {
  const { size = 'small', orientation = 'horizontal' } = props
  return <StyledSeparator {...props} orientation={orientation} size={size} />
}
