'use client'

import Image from 'next/image'
import { CSS, styled } from 'pattern-library/src/stitches.config'
import { FC } from 'react'

import type { THEME_PALETTE } from '../../src/hoc/withTheme'
import { useGetAllAppContext } from '../../src/hooks/useGetAllAppContext'
import { getAppLogo } from '../../src/utils/publication-utils'

type AppLogoProps = {
  requestedHeight: number
  css?: CSS
  themePalette?: THEME_PALETTE
  reverseThemePalette?: boolean
}

export const AppLogo: FC<AppLogoProps> = ({ requestedHeight, css, reverseThemePalette = false }) => {
  const { themePalette: _themePalette } = useGetAllAppContext()

  const themePalette = reverseThemePalette ? (_themePalette === 'dark' ? 'light' : 'dark') : _themePalette

  const logoInfo = getAppLogo({
    themePalette,
    requestedHeight,
  })

  if (!logoInfo) return null

  return (
    <ImageStyled
      src={logoInfo.logoSrc}
      alt="PubGen Logo"
      height={logoInfo.logoHeight}
      width={logoInfo.logoWidth}
      css={css}
    />
  )
}

const ImageStyled = styled(Image, {})
