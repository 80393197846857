import { FC } from 'react'

import { Text } from '../basicUI/Text'
import { CSS, css, keyframes, styled } from '../stitches.config'

export interface TextCubeProps {
  /**
   * Give classname to this component.
   */
  className?: string

  /**
   * Give css property name to this component.
   */
  css?: CSS

  /**
   * Title
   */
  title: string

  /**
   * State of the navigation
   */
  state?: 'default' | 'loading'

  /**
   * onClick
   */
  onClick?: () => void
}

// based on https://codepen.io/marko-zub/pen/mzPeOV?editors=1100
export const TextCube: FC<TextCubeProps> = ({ title, ...props }) => (
  <Container {...props}>
    <TextStyled variant="h3" oll={false}>
      {title}
    </TextStyled>
    <BgTop>
      <BgInnerTop />
    </BgTop>
    <BgRight>
      <BgInnerRight />
    </BgRight>
    <BgFront>
      <BgInnerFront />
    </BgFront>
  </Container>
)

const TextStyled = styled(Text, {
  position: 'relative',
  zIndex: 2,
  padding: '$5 $4',
  width: 'calc(100% - 1ch)',
  display: 'block',
  background: 'transparent',
  color: '$gs10',
  transition: 'color 0.2s ease-in-out',
  textAlign: 'center',
})

const sharedBg = css({
  background: '$gs4',
  transition: 'background 0.2s ease-in-out',
  position: 'absolute',
})

const BgTop = styled('div', sharedBg, {
  height: '10px',
  bottom: '100%',
  left: '5px',
  right: '-5px',
  transform: 'skew(-45deg, 0)',
  margin: '0',
})

const BgRight = styled('div', sharedBg, {
  top: '-5px',
  zIndex: '0',
  bottom: '5px',
  width: '10px',
  left: '100%',
  transform: 'skew(0, -45deg)',
})

const BgFront = styled('div', sharedBg, {
  inset: '0',
})

const sharedInner = css({
  transition: 'all 0.2s ease-in-out',
  position: 'absolute',
  background: '$w1',
})

const BgInnerTop = styled('div', sharedInner, {
  bottom: '0',
  left: '2px',
  top: '2px',
  right: '2px',
})

const BgInnerRight = styled('div', sharedInner, {
  bottom: '2px',
  left: '0',
  top: '2px',
  right: '2px',
})

const BgInnerFront = styled('div', sharedInner, {
  left: '2px',
  top: '2px',
  right: '2px',
  bottom: '2px',
})

const loadingAnimation = keyframes({
  '0%': { bottom: 0 },
  '100%': { bottom: '100%' },
})

const Container = styled('div', {
  position: ' relative',
  variants: {
    state: {
      loading: {
        cursor: 'wait',
        [`${BgInnerFront}`]: {
          // background: '$b3',
          animation: `${loadingAnimation} 5s ease-in-out infinite`,
        },
      },
      default: {
        cursor: 'pointer',
        '&:hover': {
          [`${BgTop}`]: {
            background: '$gs3',
          },
          [`${BgRight}`]: {
            background: '$gs3',
          },
          [`${BgFront}`]: {
            background: '$gs3',
          },
          [`${TextStyled}`]: {
            color: '$gs12',
          },
        },
      },
    },
  },
  defaultVariants: {
    state: 'default',
  },
})
