import { keyframes, styled } from '../stitches.config'

// type SkeletonProps = {
//     width: string;
// }

// export const Skeleton:React.FC<SkeletonProps> = ({

// }) => {
//     return (
//         <Container>

//         </Container>
//     )
// }

// const Container = styled('div', {

// })

const shimmer = keyframes({
  '0%': {
    backgroundPosition: 'calc(0% - 300px) 0',
  },
  '20%': {
    backgroundPosition: 'calc(0% - 300px) 0',
  },
  '80%': {
    backgroundPosition: 'calc(100% + 300px) 0',
  },
  '100%': {
    backgroundPosition: 'calc(100% + 300px) 0',
  },
})

export const Skeleton = styled('div', {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  backgroundColor: '$gs5',
  overflow: 'hidden',
  animation: `${shimmer} 2s linear 0.5s infinite`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '50% 100%',
  variants: {
    color: {
      normal: {
        backgroundColor: '$gs3',
        backgroundImage: 'linear-gradient(90deg, transparent,  $colors$gs7, transparent);',
      },
      inverted: {
        backgroundColor: '$gs7',
        backgroundImage: 'linear-gradient(90deg, transparent,  $colors$gs3, transparent);',
      },
    },
    variant: {
      circular: {
        borderRadius: '999999px',
      },
      rounded: {
        borderRadius: '$4',
      },
      rectangular: {
        borderRadius: '0',
      },
    },
    verticalSpacing: {
      none: {
        marginTop: '0',
        marginBottom: '0',
      },
      small: {
        marginTop: '$1',
        marginBottom: '$1',
      },
      medium: {
        marginTop: '$3',
        marginBottom: '$3',
      },
      large: {
        marginTop: '$5',
        marginBottom: '$5',
      },
    },
    height: {
      small: {
        height: '$2',
      },
      medium: {
        height: '$6',
      },
      large: {
        height: '$14',
      },
      xLarge: {
        height: '200px',
      },
      h1: {
        height: '$fontSizes$h1',
      },
      h2: {
        height: '$fontSizes$h2',
      },
      h3: {
        height: '$fontSizes$h3',
      },
      h4: {
        height: '$fontSizes$h4',
      },
      h5: {
        height: '$fontSizes$h5',
      },
      h6: {
        height: '$fontSizes$h6',
      },
      subtitle1: {
        height: '$fontSizes$subtitle1',
      },
      subtitle2: {
        height: '$fontSizes$subtitle2',
      },
      body1: {
        height: '$fontSizes$body1',
      },
      body2: {
        height: '$fontSizes$body2',
      },
      button: {
        height: '$fontSizes$button',
      },
      caption: {
        height: '$fontSizes$caption',
      },
      overline: {
        height: '$fontSizes$overline',
      },
      blockquote: {
        height: '$fontSizes$blockquote',
      },
      code: {
        height: '$fontSizes$code',
      },
    },
    width: {
      quarter: {
        width: '25%',
      },
      half: {
        width: '50%',
      },
      threeQuarters: {
        width: '75%',
      },
      full: {
        width: '100%',
      },
    },
  },
  defaultVariants: {
    variant: 'rounded',
    verticalSpacing: 'small',
    height: 'small',
    color: 'normal',
    width: 'full',
  },
})
