import React from 'react'

import { CSS, styled } from '../stitches.config'
import { ShoImage } from './ShoImage'
import { Text } from './Text'

export interface AvatarProps {
  /**
   * Ability to change the default icon.
   * */
  customIconName?: string

  /**
   * Give css property to accordion component.
   */
  css?: CSS

  /**
   * Initials of the user's first name.
   */
  firstName?: string

  /**
   * img
   * */
  imgSrc?: string

  imgWidth?: number

  imgHeight?: number

  imgType?: 'circle' | 'square'

  /**
   * Initials of the user's last name.
   */
  lastName?: string

  customText?: string
}

// TODO: add option for image
export const Avatar: React.FC<AvatarProps> = ({
  css,
  firstName,
  lastName,
  customText,
  customIconName,
  imgSrc,
  imgHeight,
  imgWidth,
  imgType = 'circle',
}) => {
  const _firstNameInitial = firstName ? firstName.slice(0, 1).toUpperCase() : ''
  const _lastNameInitial = lastName ? lastName.slice(0, 1).toUpperCase() : ''
  const hasInitials = _firstNameInitial || _lastNameInitial
  const type = imgSrc && imgHeight && imgWidth ? 'image' : customText ? 'customText' : hasInitials ? 'initials' : 'icon'

  return (
    <Container css={css} radius={imgType}>
      {type === 'image' && (
        <ShoImage
          src={imgSrc || ''}
          width={imgWidth}
          height={imgHeight}
          alt={`${firstName} ${lastName}`}
          sizes="32px"
          imageCss={{
            width: '32px',
            height: '32px',
            borderRadius: imgType === 'circle' ? '50%' : '0%',
            objectFit: 'cover',
          }}
        />
      )}
      {type === 'initials' && (
        <InitialsContainer>
          {_firstNameInitial}
          {_lastNameInitial}
        </InitialsContainer>
      )}
      {type === 'icon' && <span className="material-icons-outlined">{customIconName || 'person'}</span>}
      {type === 'customText' && <InitialsContainer>{customText}</InitialsContainer>}
    </Container>
  )
}

const Container = styled(Text, {
  backgroundColor: '$b5',
  color: '$gs8',
  borderRadius: '50%',
  width: '32px',
  minWidth: '32px',
  height: '32px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  lineHeight: '32px !important',
  variants: {
    radius: {
      circle: {
        borderRadius: '50%',
      },
      square: {
        borderRadius: '0%',
      },
    },
  },
  defaultVariants: {
    radius: 'circle',
  },
})
const InitialsContainer = styled('span', {
  fontSize: '14px',
  textTransform: 'capitalize',
})
